<template>
  <div class="cal_box main">
    <div class="container content">
      <div class="cal_title">Upcoming Events</div>
      <div class="cal_menu">
        <div class="btn prev">&lt;&lt; Prev</div>
        <div class="btn next">Next >></div>
      </div>
      <div class="cal_con">
        <img src="@/assets/images/calender.jpg" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Calendar',
  components: {},
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>
<style lang="scss" scoped>
.cal_box {
  .cal_title {
    font-size: 30px;
    font-weight: bold;
    padding-bottom: 10px;
    border-bottom: 1px solid $font;
  }
  .cal_menu {
    float: right;
    margin-top: 10px;
    .btn {
      float: left;
      background-color: #dfdfdf;
      width: 80px;
      text-align: center;
      font-weight: bold;
      line-height: 30px;
      cursor: pointer;
    }
    .prev {
      border-radius: 10px 0 0 10px;
      margin-right: 10px;
    }
    .next {
      border-radius: 0 10px 10px 0;
    }
  }
  .cal_con {
    margin-top: 20px;
    img {
      width: 100%;
    }
  }
}
</style>
